import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ThankYouPage } from 'components/shared/ThankYou';
import routes from 'constants/routes';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import theme from 'theme/theme';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const Router = () => (
  <HashRouter>
    <Routes>
      <Route path={routes.LOCATION_DETECTION} element={<App />} />
      <Route path={routes.THANK_YOU} element={<ThankYouPage />} />
      <Route
        path="*"
        element={<Navigate to={routes.LOCATION_DETECTION} replace />}
      />
    </Routes>
  </HashRouter>
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  </React.StrictMode>,
);
