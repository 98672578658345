import { createTheme } from '@mui/material/styles';
import { colors } from './colors';

const theme = createTheme({
  typography: {
    fontFamily: [`"Source Sans Pro"`, `sans-serif`].join(','),
    fontSize: 17,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h6: { fontWeight: 600 },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { fontSize: '1rem' },
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
  },
});

export default theme;
