const translation = {
  CURRENT_LOCATION: 'LOCALISATION ACTUELLE',
  CONFIRM_LOCATION: "Confirmer l'emplacement",
  PAGE_NOT_FOUND: 'Page non trouvée!',
  MISSING_URL_PARAMETERS: "Il semble qu'il manque des paramètres d'URL...",
  NOT_CORRECT: 'Pas correcte?',
  NEED_HELP: "Besoin d'aide?",
  CALL_US: 'Appelez-nous',
  THANK_YOU: 'Merci!',
  LOCATION_WAS_DETECTED: 'Votre position a été détectée avec succès.',
  MOVE_THE_MAP_OR_ADJUST:
    'Déplacez la carte ou effectuez une recherche pour ajuster la position de votre véhicule.',
  THANKS: 'Merci!',
  CHECKING_YOUR_LOCATION: 'Nous vérifions votre position maintenant.',
  AS_A_REMINDER:
    "Pour rappel, nous ne le ferons qu'une seule fois, et jamais sans votre autorisation.",
  UNABLE_TO_GET_POSITION: `Impossible d'obtenir la position actuelle.`,
  LOCATION_PRIVILEGES_ARE_ENABLED:
    'Assurez-vous que les privilèges de localisation sont activés dans les paramètres pour des résultats précis.',
};

export default translation;
