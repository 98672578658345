export const analyticsParams = {
  vin: 'VIN_LOOKUP',
  policy: 'POLICY_LOOKUP',
  fakeCard: 'USED_NEW_CARD',
  location: 'PICK_UP_LOCATION',
  dropOff: 'DROP_OFF_LOCATION',
  card: `CREDIT CARD SELECTED`,
  vehicleSelect: 'VEHICLE SELECTED',
  additionalNotes: 'ADDITIONAL_NOTES',
  contactInfo: 'CONTACT_INFORMATION',
  color: 'VEHICLE_COLOR_CONFIRMATION',
  textQuestion: 'INPUT ANSWER SUBMITTED',
  vehicleStuck: 'VEHICLE_STUCK_ANSWER_SUBMITTED',
};

export const pageViews = {
  thankYou: 'THANK_YOU_PAGE_VIEW',
};

const questionAnalytics = {
  policyCheck: 'POLICY_SUBMIT',
  callus: 'CALL_US_BUTTON_CLICKED',
  payment: 'INITIATE_PAYMENT_URL',
  editQuestion: 'USER_EDITED_ANSWER',
  cardChosen: 'CREDIT_CARD_SELECTED',
  chooseVehicle: 'VEHICLE_IS_SELECTED',
  dvnOrVinCheck: 'DVN OR VIN SUBMIT',
  serviceRequest: 'SERVICE_REQUESTED',
  somethingWentWrong: 'ERROR_SCREEN',
  exhausted: 'EXHAUSTED_SCREEN_VISIBLE',
  thankYou: 'REDIRECTING_TO_TRACKER_APP',
  overageConfirmed: 'OVERAGE_CONFIRMED',
  missingStreet: 'MISSING_STREET_ADDRESS',
  quickQuestion: 'MULTIPLE_CHOICE_QUESTION',
  coverageScreen: 'COVERAGE_SCREEN_VIEWED',
  mapNotification: 'MAP_NOTIFICATION_CLOSED',
  mapBack: 'MAP_SEARCH_BACK_BUTTON_CLICKED',
  mapConfirmation: 'MAP_CONFIRMATION_CLICKED',
  coverageScreenClosed: 'COVERAGE_SCREEN_CLOSED',
  mapCurrentLocation: 'MAP_CURRENT_BUTTON_CLICKED',
  currentQuestion: 'CURRENT_QUESTION_ANSWER_SUBMITED',
};

export default questionAnalytics;
