import languageEN from 'assets/languages/languageEN';
import languageFR from 'assets/languages/languageFR';

export const getTranslations = (langCode: string): Record<string, string> => {
  switch (langCode?.toLocaleLowerCase()) {
    case 'en':
      return languageEN;
    case 'fr':
      return languageFR;
    default:
      return languageEN;
  }
};
export const getLanguage = (): string => {
  const urlSearchParams = new URLSearchParams(
    window.location.hash.split('?')[1],
  );
  const [language] = (urlSearchParams.get('langCode') || '')
    .toLocaleLowerCase()
    .split('_');

  if (language === 'fr') {
    return 'fr';
  } else {
    return 'en';
  }
};
