const translation = {
  CURRENT_LOCATION: 'CURRENT LOCATION',
  CONFIRM_LOCATION: 'Confirm Location',
  PAGE_NOT_FOUND: 'Page not found!',
  MISSING_URL_PARAMETERS: 'It seems there are missing URL parameters...',
  NOT_CORRECT: 'Not Correct?',
  NEED_HELP: 'Need help?',
  CALL_US: 'Call Us',
  THANK_YOU: 'Thank You!',
  LOCATION_WAS_DETECTED: 'Your location was detected successfully.',
  MOVE_THE_MAP_OR_ADJUST:
    "Move the map or search to adjust your vehicle's location.",
  THANKS: 'Thanks!',
  CHECKING_YOUR_LOCATION: "We're checking your location now.",
  AS_A_REMINDER:
    'As a reminder, we will only do this one time, and never without your permission.',
  UNABLE_TO_GET_POSITION: 'Unable to get current position.',
  LOCATION_PRIVILEGES_ARE_ENABLED:
    'Make sure location privileges are enabled in the settings for accurate results.',
};

export default translation;
