import { gaTrackEvent } from '@geturgently/ui-util';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import analytics from 'constants/analytics';
import dataTestIds from 'constants/dataTestIds';
import { FC } from 'react';
import { colors } from 'theme/colors';
import {
  CloseIconBox,
  InfoIconBox,
  NotificationBox,
  TypographiesBox,
} from './Notification.styles';

interface INotificationProps {
  title?: string;
  message?: string;
  onClose: () => void;
  styles?: Record<string, string>;
  translations?: Record<string, string>;
}

const Notification: FC<INotificationProps> = ({
  title,
  onClose,
  message,
  styles,
  translations,
}) => (
  <NotificationBox
    bgcolor={styles?.bgcolor}
    data-testid={dataTestIds.mapNotification}
  >
    {!styles && (
      <InfoIconBox data-testid={dataTestIds.mapNotificationInfoIcon}>
        <InfoOutlinedIcon color="secondary" />
      </InfoIconBox>
    )}
    <TypographiesBox>
      <Typography
        fontSize="16px"
        lineHeight={1.5}
        color={styles?.color || colors.text}
        data-testid={dataTestIds.mapNotificationTitle}
      >
        {title || translations?.NOT_CORRECT}
      </Typography>
      <Typography
        fontSize="14px"
        lineHeight={1.43}
        color={styles?.color || colors.text}
        data-testid={dataTestIds.mapNotificationMessage}
      >
        {message}
      </Typography>
    </TypographiesBox>
    <CloseIconBox>
      <CloseIcon
        data-testid={dataTestIds.mapNotificationClose}
        style={{
          width: 20,
          height: 20,
          color: styles?.color || '',
        }}
        onClick={() => {
          onClose();
          gaTrackEvent(analytics.mapNotification, {});
        }}
      />
    </CloseIconBox>
  </NotificationBox>
);

export default Notification;
