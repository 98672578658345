import Typography from '@mui/material/Typography';
import dataTestIds from 'constants/dataTestIds';
import { NotFoundBox } from './NotFound.styles';

const NotFoundPage = ({
  translations,
}: {
  translations?: Record<string, string>;
}): JSX.Element => {
  return (
    <NotFoundBox>
      <Typography variant="h4" data-testid={dataTestIds.pageNotFoundHeader}>
        {translations?.PAGE_NOT_FOUND}
      </Typography>
      <Typography data-testid={dataTestIds.pageNotFoundText}>
        {translations?.MISSING_URL_PARAMETERS}
      </Typography>
    </NotFoundBox>
  );
};
export default NotFoundPage;
