import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { colors } from 'theme/colors';

type BoxProps = {
  bgcolor?: string;
};

export const NotificationBox = styled(Box)<BoxProps>(({ bgcolor }) => ({
  display: 'flex',
  backgroundColor: bgcolor || colors.info,
  padding: '16px',
  borderRadius: '4px',
  width: 'fit-content',
}));

export const InfoIconBox = styled(Box)({
  display: 'flex',
  alignSelf: 'baseline',
});

export const CloseIconBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginLeft: 'auto',
});

export const TypographiesBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 16px',
  height: '100%',
});
