import { sessionStorage } from '@geturgently/ui-util';
import Typography from '@mui/material/Typography';
import dataTestIds from 'constants/dataTestIds';
import { getTranslations } from 'utils/languages';
import { ThankYouBox } from './ThankYou.styles';

export const ThankYouPage = (): JSX.Element => {
  const partnerName = sessionStorage.getItem('partnerName');
  const langCode = sessionStorage.getItem('langCode');
  const translations = getTranslations(langCode as string);

  return (
    <ThankYouBox>
      <Typography
        variant="h5"
        mb={6}
        fontWeight="bold"
        data-testid={dataTestIds.thankYouPartnerName}
      >
        {partnerName}
      </Typography>
      <Typography data-testid={dataTestIds.thankYouThanks}>
        {translations.THANKS}
      </Typography>
      <Typography data-testid={dataTestIds.thankYouCheckingYourLocation}>
        {translations.CHECKING_YOUR_LOCATION}
      </Typography>
      <Typography
        align="center"
        my={2}
        mx={4}
        data-testid={dataTestIds.thankYouAsAReminder}
      >
        {translations.AS_A_REMINDER}
      </Typography>
    </ThankYouBox>
  );
};
