import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type BoxProps = {
  pageSizeState: { width: number; height: number };
};

export const MapBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'pageSizeState',
})<BoxProps>(({ pageSizeState }) => ({
  top: 0,
  left: 0,
  zIndex: 10,
  width: pageSizeState.width,
  height: pageSizeState.height,
  position: 'fixed',
  backgroundColor: 'white',
  '[data-testid="header-label"]': {
    textAlign: 'center',
    marginLeft: '32px',
  },
}));

export const NotificationBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  top: '80px',
  left: '16px',
  position: 'absolute',
});

export const CurrentLocationBox = styled(Box)({
  display: 'flex',
  width: '100%',
  bottom: '154px',
  position: 'absolute',
  justifyContent: 'center',
});

export const CurrentLocationButton = styled(Button)({
  borderRadius: '24px',
});

export const Footer = styled(Box)({
  display: 'flex',
  bottom: 0,
  width: '100%',
  position: 'absolute',
  padding: '17px 32px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: 'white',
});

export const FooterTexts = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const FooterTitle = styled(Typography)({
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: 500,
});

export const LocationInfo = styled(Typography)({
  fontSize: '16px',
  lineHeight: '22px',
});
