import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const ThankYouBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  flexDirection: 'column',
});
