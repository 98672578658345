const dataTestIds = {
  zip: 'zip',
  year: 'year',
  color: 'color',
  make: 'make',
  model: 'model',
  callus: 'callus',
  policy: 'policy',
  header: 'header',
  vehicle: 'vehicle',
  lastname: 'lastname',
  tryAgain: 'try-again',
  firstname: 'firstname',
  textInput: 'text-input',
  policyEdit: 'policy-edit',
  contactEdit: 'contact-edit',
  getLocation: 'get-location',
  locationEdit: 'location-edit',
  newVehicle: 'new-vehicle',
  licensePlate: 'license-plate',
  policyLookup: 'policy-lookup',
  otherVehicle: 'other-vehicle',
  phonenumber: 'phonenumber',
  otherLocation: 'other-location',
  thankYouTitle: 'thank-you-title',
  submitContact: 'submit-contact',
  textInputEdit: 'text-input-edit',
  requestService: 'request-service',
  dropOffLocation: 'dropOff-location',
  currentLocation: 'current-location',
  overageContinue: 'overage-continue',
  vehicleColorEdit: 'vehicle-color-edit',
  textInputConfirm: 'text-input-confirm',
  thankYouContinue: 'thank-you-continue',
  vehicleColorInput: 'vehicle-color-input',
  vehicleStuckOther: 'vehicle-stuck-other',
  thankYouHelpText: 'thank-you-help-text',
  vehicleStuckSubmit: 'vehicle-stuck-submit',
  dropOffLocationEdit: 'dropOff-location-edit',
  viewLocationOnMap: 'view-location-on-map',
  mapCurrentLocation: 'map-current-location',
  vehicleColorConfirm: 'vehicle-color-confirm',
  vehicleSelectionEdit: 'vehicle-selection-edit',
  dropOffLocationOther: 'dropOff-location-other',
  additionalNotesInput: 'additional-notes-input',
  mapNotification: 'map-notification',
  mapNotificationInfoIcon: 'map-notification-info-icon',
  mapNotificationTitle: 'map-notification-title',
  mapNotificationMessage: 'map-notification-message',
  mapNotificationClose: 'map-notification-close',
  additionalNotesSubmit: 'additional-notes-submit',
  vehicleSelectionSubmit: 'vehicle-selection-submit',
  dropOffLocationViewOnMap: 'dropOff-location-view-on-map',
  mapConfirmLocation: 'map-confirm-location',
  checkoutContinue: 'checkout-continue',
  pageNotFoundHeader: 'page-not-found-header',
  pageNotFoundText: 'page-not-found-text',
  mapFooterInfo: 'map-footer-info',
  mapFooterTitle: 'map-footer-title',
  mapFooterLocationInfo: 'map-footer-location-info',
  mapFooterLocationAdditionalInfo: 'map-footer-location-additional-info',
  mapNeedHelp: 'map-need-help',
  mapCallUs: 'map-call-us',
  thankYouPartnerName: 'thank-you-partner-name',
  thankYouThanks: 'thank-you-thanks',
  thankYouCheckingYourLocation: 'thank-you-checking-your-location',
  thankYouAsAReminder: 'thank-you-as-a-reminder',
};

export default dataTestIds;
